import Vue from 'vue'
import Vuex from 'vuex'
import clubs from './modules/clubs';
import competitions from './modules/competitions';
import personel from './modules/personel';
import notification from './modules/notification';
import management from './modules/management';
import federation from './modules/federation';
import auth from './modules/auth';
import map from './modules/map';
import message from "./modules/message"
import competitionGroup from "./modules/competitionGroup";
import report from "./modules/report";
import match from "./modules/match";
import individual_group from './modules/individual_group';
import CKEditor from '@ckeditor/ckeditor5-vue2';


Vue.use(Vuex)
Vue.use(CKEditor);

export default new Vuex.Store({
  state: {
    PMSTablePaginationCurrentPage: 1
  },
  getters: {
    getPMSTablePaginationCurrentPage(state) {
      return state.PMSTablePaginationCurrentPage;
    }
  },
  mutations: {
    updatePMSTablePaginationCurrentPage(state, page) {
      state.PMSTablePaginationCurrentPage = page
    }
  },
  actions: {
  },
  modules: {
    clubs,
    competitions,
    personel,
    management,
    CKEditor,
    notification,
    individual_group,
    map,
    competitionGroup,
    federation,
    auth,
    match,
    report,
    message
  }
})
