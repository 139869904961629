import PMSAxiosHelper from '../helpers/PMSAxiosHelper';
import constants from '../constants';
import store from '..';
const state = {
  competitions: [],
  competitionTotalPageCount: 1,
  currentPage: 1,
  singleCompetition: {
    createdAt: "",
    kind: "",
    location: "",
    election: {},
    startingDate: "",
    endingDate: "",
    category: "",
    branch: ""
  },
  competitionNames: [],
  filter: {
    selectedOptions: [],
    where: null
  },

}

const getters = {
  getCompetitions(state) {
    return state.competitions;
  },
  getCompetitionFields(state) {
    return state.competitionFields;
  },
  getCompetitionTotalPageCount(state) {
    return state.competitionTotalPageCount;
  },
  getCompetitionCurrentPage(state) {
    return state.currentPage;
  },
  getCompetitionFilter(state) {
    return state.filter;
  },
  getSingleCompetition(state) {
    return state.singleCompetition;
  },
  getCompetitionNames(state) {
    return state.competitionNames;
  }
}

const mutations = {
  updateCompetitionList(state, competition) {
    state.competitions = competition;
  },
  clearCompetitionList(state) {
    state.competitions.clear();
  },
  updateCompetitionTotalPageCount(state, count) {
    state.competitionTotalPageCount = count;
  },
  addCompetition(state, competition) {
    state.competitions.push(competition);
  },
  updateCompetition(state, competition) {
    state.competitions = state.competitions.map(element => element.id == competition.id ? competition : element);
  },
  updateCompetitionFilter(state, newFilter) {
    state.filter = newFilter;
  },
  updateSingleCompetition(state, competition) {
    state.singleCompetition = competition;
  },
  updateCompetitionNames(state, competition) {
    state.competitionNames = competition;
  },
}

const actions = {
  initCompetitions({ commit, dispatch }, payload) {
    var currentPage = payload ?? 1;
    PMSAxiosHelper
      .get("/api/competition/",
        {
          params:
          {
            lim: constants.MAX_TABLE_ITEMS,
            off: constants.MAX_TABLE_ITEMS * (currentPage - 1),
            orgWillBeDone: state.filter.selectedOptions.includes("orgWillBeDone", 0) ? 1 : "",
            orgHasDone: state.filter.selectedOptions.includes("orgHasDone", 0) ? 1 : "",
            where: state.filter.where ?? ""
          }
        })
      .then((response) => {
        commit("updateCompetitionList", response.result)
      });
    dispatch("getCompetitionTotalPageCountFromServer");
  },
  updateCompetition({ commit }, payload) {
    const formData = new FormData();
    const photo = payload.pdfAddress;
    delete payload.pdfAddress;
    payload.pdfAddress = photo;
    if (!(payload.pdfAddress instanceof File)) {
      delete payload.pdfAddress;
    }
    PMSAxiosHelper.buildFormData(formData, payload);
    PMSAxiosHelper
      .put("/api/competition/" + payload.id, formData)
      .then(() => {
        commit("updateCompetition", payload);
        //update durumunda response data dönmüyor bu yüzden payload konuluyor
      });
  },
  saveCompetition({ dispatch }, payload) {
    const formData = new FormData();
    const photo = payload.pdfAddress;
    delete payload.pdfAddress;
    payload.pdfAddress = photo;
    if (!(payload.pdfAddress instanceof File)) {
      delete payload.pdfAddress;
    }
    PMSAxiosHelper.buildFormData(formData, payload);
    PMSAxiosHelper
      .post("/api/competition/add", formData)
      .then(() => {
        dispatch("initCompetitions");
      });
    store.dispatch("notification/setNotifications", [{ title: "Yarışma kaydedildi!", body: "Yarışma başarıyla kaydedildi", type: "success" }]);
  },
  getCompetitionTotalPageCountFromServer({ commit },) {
    PMSAxiosHelper
      .get("/api/competition/getAllItemsCount", {
        params: {
          orgWillBeDone: state.filter.selectedOptions.includes("orgWillBeDone", 0),
          orgHasDone: state.filter.selectedOptions.includes("orgHasDone", 0),
          where: state.filter.where ?? ""
        }
      })
      .then((response) => {
        commit("updateCompetitionTotalPageCount", Math.ceil(response.result / constants.MAX_TABLE_ITEMS))
      });
  },
  deleteCompetitions({ dispatch }, payload) {
    PMSAxiosHelper
      .delete("/api/competition/deleteItems/", payload)
      .then(() => {
        dispatch("initCompetitions")
      });
    store.dispatch("notification/setNotifications", [{ title: "Yarışmalar silindi!", body: "Yarışmalar başarıyla silindi", type: "success" }]);

  },
  async initSingleCompetition({ commit }, payload) {
    return PMSAxiosHelper
      .get("/api/competition/" + payload)
      .then((response) => {
        commit("updateSingleCompetition", response.result)
        return response;
      });
  },
  async initCompetitionNames({ commit }) {
    return PMSAxiosHelper
      .get("/api/competition/names")
      .then((response) => {
        commit("updateCompetitionNames", response.result)
        return response;
      });
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
