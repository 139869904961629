import PMSAxiosHelper from '../helpers/PMSAxiosHelper';

const state = {
    managementItems: []
}

const getters = {
    getManagementItems(state) {
        return state.managementItems;
    },

}

const mutations = {
    updateManagementItems(state, managementItem) {
        state.managementItems = managementItem;
    },
}

const actions = {
    async initManagement({ commit },) {
        const response = await PMSAxiosHelper
            .get("/api/election/",)
        await commit("updateManagementItems", response.result);
        return response;
    },
    saveManagement({ dispatch }, payload) {
        PMSAxiosHelper
            .post("/api/election/add", payload)
            .then(() => {
                dispatch("initManagement");
            });
    },
    deleteManagementItem({ dispatch }, payload) {
        PMSAxiosHelper
            .delete("/api/election/" + payload)
            .then(() => {
                dispatch("initManagement");
            });
    },
    editManagementItem({ dispatch }, payload) {
        PMSAxiosHelper
            .put("/api/election/" + payload, payload)
            .then(() => {
                dispatch("initManagement");
            });
    }
}


export default {
    state,
    getters,
    mutations,
    actions
}

