
import PMSAxiosHelper from '../helpers/PMSAxiosHelper';

const state = {
  individualGroups: [],
  singleIndividualGroup: {},
}

const getters = {

  getIndividualGroups(state) {

    return state.individualGroups;
  },
  getSingleIndividualGroup(state) {
      return state.singleIndividualGroup;
    }

}

const mutations = {
  updateIndividualGroups(state, individualGroup) {

    state.individualGroups = individualGroup;
  },
  updateSingleIndividualGroup(state, individualGroup) {
    state.singleIndividualGroup = individualGroup;
  }
}

const actions = {
  getIndividualGroupsByCompetitionId({ commit }, competitionId) {


    PMSAxiosHelper
      .get("/api/individual_group/" + competitionId)
      .then((response) => {
        commit("updateIndividualGroups", response.result);

      });
  },
  getIndividualGroupsByCompetitionIdAndUserId({ commit }, payload) {
    PMSAxiosHelper
      .get("/api/individual_group/user/" + payload.competitionId + "/" + payload.userId)
      .then((response) => {
        commit("updateIndividualGroups", response.result);

      });
  },
  approveMultipleIndividualGroups({ dispatch }, payload) {
    return PMSAxiosHelper.post("/api/individual_group/approve", payload)
      .then(async () => {
        await dispatch("getIndividualGroupsByCompetitionId", payload.competitionId);
      })
      .catch(() => {
        // Handle error if the post request fails
      });
  },
  deleteMultipleIndividualGroups({ dispatch }, payload) {
    return PMSAxiosHelper.delete("/api/individual_group/", payload)
      .then(async () => {
        await dispatch("getIndividualGroupsByCompetitionId", payload.competitionId);
      })
      .catch(() => {
        // Handle error if the post request fails
      });
  },
  deleteMultipleIndividualGroupsUser({ dispatch }, payload) {
    return PMSAxiosHelper.delete("/api/individual_group/",{
      ids:payload.ids,
    })
      .then(async () => {
        await dispatch("getIndividualGroupsByCompetitionIdAndUserId", payload.params);
      })
      .catch(() => {
        // Handle error if the post request fails
      });
  },
  joinIndividualGroup(_, payload) {
    return PMSAxiosHelper.post("/api/individual_group/join", payload)
      .then(async (response) => {
     
        return response.result;
      })
      .catch(() => {
        // Handle error if the post request fails
      });
  },
  exitIndividualGroup({dispatch}, payload) {
    return PMSAxiosHelper.post("/api/individual_group/exit", payload)
      .then(async () => {

        await dispatch("getIndividualGroupsByCompetitionIdAndUserId", payload);
      })
      .catch(() => {
        // Handle error if the post request fails
      });
  },
  async getIndividualGroupByGroupCode({ commit }, payload) {
   return await  PMSAxiosHelper
      .get("/api/individual_group/"+payload.competitionId+"/" +payload.uid)
      .then((response) => {
        commit("updateSingleIndividualGroup", response.result);
        return response.result;
      }
      );
  },

  createIndividualGroup(_, payload) {
    return PMSAxiosHelper
      .post("/api/individual_group/add", payload)
  }
}


export default {
  state,
  getters,
  mutations,
  actions
}

