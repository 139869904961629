import PMSAxiosHelper from '../helpers/PMSAxiosHelper';
import store from '..';
const state = {
    reportItems: [],
    selectedReport: null
}

const getters = {
    getReportItems(state) {
        return state.reportItems;
    },
    getSelectedReport(state) {
        return state.selectedReport;
    }
}

const mutations = {
    updateReportItems(state, reportItem) {
        state.reportItems = reportItem;
    },
    updateSelectedReport(state, report) {
        state.selectedReport = report;
    }
}

const actions = {
    initReportItems({ commit }) {
        PMSAxiosHelper
            .get("/api/reports/")
            .then((response) => {
                commit("updateReportItems", response.result);
            });
    },
    async initSelectedReport({ commit }, payload) {
        if (state.selectedReport && payload == state.selectedReport.id) {
            return state.selectedReport;
        } else {
            return PMSAxiosHelper
                .get("/api/reports/" + payload)
                .then((response) => {
                    commit("updateSelectedReport", response.result);
                    return response;
                });
        }
    },
    saveReport({ dispatch }, payload) {
        PMSAxiosHelper
            .post("/api/reports/", payload)
            .then(() => {
                dispatch("initReportItems");
            });
        store.dispatch("notification/setNotifications", [{ title: "Rapor kaydedildi!", body: "Rapor başarıyla kaydedildi", type: "success" }]);
    },
    deleteReportItem({ dispatch }, payload) {
        PMSAxiosHelper
            .delete("/api/reports/", payload)
            .then(() => {
                dispatch("initReportItems");
            });

        store.dispatch("notification/setNotifications", [{ title: "Rapor silindi!", body: "Rapor başarıyla silindi", type: "success" }]);
    },
    editReportItem({ dispatch, commit }, payload) {
        PMSAxiosHelper
            .put("/api/reports/", payload)
            .then(() => {
                dispatch("initReportItems");
                commit("updateSelectedReport", null);
            });
        store.dispatch("notification/setNotifications", [{ title: "Rapor güncellendi!", body: "Rapor başarıyla güncellendi", type: "success" }]);
    },
    async printReport(_, payload) {
        return await PMSAxiosHelper
            .get("/api/reports/" + payload)
    }
}


export default {
    state,
    getters,
    mutations,
    actions
}

