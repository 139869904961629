import PMSAxiosHelper from '../helpers/PMSAxiosHelper';
import constants from '../constants';
import store from "../index.js";
const state = {
    personel: [],
    individualPersonel: [],
    competitionPersonel: [],
    clubCompetitionPersonel: [],
    messagePersonel: [],
    personelAddressIdList: {
        count: 0,
        data: {}
    },
    visas: [],
    image: "",
    reportingPersonelData:{},
    personelAddresItem: {
        //Personal Information

        ssn: "", //Baskan

        surname: "", //Kurulus tarihi
        name: "", //Kapanis tarihi
        gender: "", //Adres

        birthDate: "", //Deniz kanosu son akredite yili

        photoAddress: "", //Takim fotograf adresi

        //Branch Information
        branch: {
            clubId: 0, //Adi
            secondClubId: 0, //Adi
            size: "", //Baskan
            weight: "", //Bolge
            bodySize: "", //Tescil No
            shoeSize: "", //Kapanis tarihi
            sportsShoeSize: "", //Adres
            branch: "", //Telefon
            category: "", //Akarsu son akredite yili
            boatClass: "", //Durgunsu son akredite yili
            sportState: "", //Deniz kanosu son akredite yili
            sportLicenceNumber: "", //Dragon bot son akredite yili
            sportLastLicenseDate: 0, //Faks
            sportRefereeState: "", //E posta adresi
            sportRefereeLastLicenseDate: 0, //Web adresi
            stillWaterCandidateRefereePromotionDate: "", //Resim adresi
            stillWaterDistrictRefereePromotionDate: "", //Takim fotograf adresi
            stillWaterNationalRefereePromotionDate: "", //Aciklama
            stillWaterInternationalRefereePromotionDate: "", //Sifre
            runningWaterCandidateRefereePromotionDate: "", //Faks
            runningWaterDistrictRefereePromotionDate: "", //E posta adresi
            runningWaterNationalRefereePromotionDate: "", //Web adresi
            runningWaterInternationalRefereePromotionDate: "", //Resim adresi
            trainerState: "", //Takim fotograf adresi
            trainerLastLicenseDate: 0, //Aciklama
            stillWaterAssistantTrainerPromotionDate: "", //Sifre
            stillWaterTrainerPromotionDate: "", //Adi
            stillWaterSeniorTrainerPromotionDate: "", //Baskan
            stillWaterHeadTrainerPromotionDate: "", //Bolge
            stillWaterCoachPromotionDate: "", //Tescil No
            runningWaterTrainerPromotionDate: "", //Kurulus tarihi
            runningWaterAssistantTrainerPromotionDate: "", //Kapanis tarihi
            runningWaterSeniorTrainerPromotionDate: "", //Adres
            runningWaterHeadTrainerPromotionDate: "", //Telefon
            runningWaterCoachPromotionDate: "", //Akarsu son akredite yili
            provincialRepresentative: "", //Durgunsu son akredite yili
            assemblyMember: "", //Deniz kanosu son akredite yili
            assembly: "", //Dragon bot son akredite yili
            personel: "", //Faks
            personelDuty: "", //E posta adresi
            manager: "", //Web adresi
            managerDuty: "", //Resim adresi
            description: "", //Takim fotograf adresi
        },
        //Contact Information
        contact: {
            workPhoneNumber: "", //Adi
            workAddress: "", //Baskan
            homePhoneNumber: "", //Bolge
            homeAdress: "", //Tescil No
            cityWhereLocated: "", //Kapanis tarihi
            districtWhereLocated: "", //Kurulus tarihi
            gsm: "", //Adres
            email: "", //Telefon
            website: "", //Akarsu son akredite yili
            bankInfo: "", //Durgunsu son akredite yili
            message: false, //Deniz kanosu son akredite yili
        },
        //Education Information
        education: {
            educationStatus: "", //Kapanis tarihi
            schoolDepartmentNo: "", //Kurulus tarihi
            lastSchoolAttended: "", //Adres
            foreignLanguage: "", //Telefon
        },
        //System Information
        system: {
            password: "", //Adres
            approval: 0, //Telefon
        },
    },
    personelTotalPageCount: 1,
    filter: {
        where: null,
        region: "",
        role: ""
    },
    personelFields: [
        {
            key: "selected",
            label: "Seç",
        },
        {
            key: "id",
            label: "Sıra No",
        },
        {
            key: "cityWhereLocated",
            label: "Bölgesi",
        },

        {
            key: "ssn",
            label: "TC Kimlik No",
        },
        {
            key: "name",
            label: "Ad Soyad",
        },
        {
            key: "approval",
            label: "Onay",
        },
        {
            key: "events",
            label: "İşlemler",
        },
    ],
}

const getters = {
    getPersonel(state) {
        return state.personel;
    },
    getCompetitionPersonel(state) {
        return state.competitionPersonel;
    },
    getClubCompetitionPersonel(state) {
        return state.clubCompetitionPersonel;
    },
    getPersonelImage(state) {
        return state.image;
    },
    getPersonelVisas(state) {
        return state.visas;
    },
    getIndividualPersonel(state) {
        return state.individualPersonel;
    },
    getPersonelFields(state) {
        return state.personelFields;
    },
    getPersonelTotalPageCount(state) {
        return state.personelTotalPageCount;
    },
    getPersonelCurrentPage(state) {
        return state.currentPage;
    },
    getPersonelFilter(state) {
        return state.filter;
    },
    getMessagePersonel(state) {
        return state.messagePersonel;
    },
    getPersonelAddressIdList(state) {
        return state.personelAddressIdList;
    },
    getPersonelAddresItem(state) {
        return state.personelAddresItem;
    },
    getReportingPersonel(state) {
        return state.reportingPersonelData;
    }
}

const mutations = {
    updatePersonelList(state, personel) {
        state.personel = personel;
    },
    updateCompetitionPersonel(state, personel) {
        state.competitionPersonel = personel;
    },
    updateClubCompetitionPersonel(state, personel) {
        state.clubCompetitionPersonel = personel;
    },
    setClubCompetitionPersonel(state, personel) {
        state.clubCompetitionPersonel = personel;
    },
    updatePersonelImage(state, image) {
        state.image = image;
    },
    updateVisas(state, visas) {
        state.visas = visas;
    },
    updateIndividualPersonel(state, personel) {
        state.individualPersonel = personel
    },
    clearPersonelList(state) {
        state.personel = [];
    },
    updatePersonelTotalPageCount(state, count) {
        state.personelTotalPageCount = count;
    },
    addPersonel(state, personel) {
        state.personel.push(personel);
    },
    updatePersonel(state, personel) {
        state.personel = state.personel.map(element => element.id == personel.id ? personel : element);
    },
    updateMessagePersonel(state, personel) {
        state.messagePersonel = personel;
    },
    updatePersonelFilter(state, newFilter) {
        state.filter = newFilter;
    },
    updatePersonelAddressIdList(state, newList) {
        state.personelAddressIdList = newList;
    },
    updatePersonelAddresItem(state, personel) {
        state.personelAddresItem = personel;
    },
    updateReportingPersonel(state,data){
        state.reportingPersonelData = data;
    }
}

const actions = {
    initPersonel({ commit, dispatch }, payload) {

        var currentPage = payload ?? 1;
        PMSAxiosHelper
            .get("/api/personel/",
                {
                    params:
                    {
                        lim: constants.MAX_TABLE_ITEMS,
                        off: constants.MAX_TABLE_ITEMS * (currentPage - 1),
                        where: state.filter.where ?? "",
                        region: state.filter.region == "" ? null : state.filter.region,
                        role: state.filter.role ?? "",
                    }
                })
            .then((response) => {
                commit("updatePersonelList", response.result)
            });
        dispatch("getPersonelTotalPageCountFromServer");
    },
    initUnapprovedPersonel({ commit, dispatch }, payload) {
        var currentPage = payload ?? 1;
        PMSAxiosHelper
            .get("/api/personel/",
                {
                    params:
                    {
                        lim: constants.MAX_TABLE_ITEMS,
                        off: constants.MAX_TABLE_ITEMS * (currentPage - 1),
                        approved: 1
                    }
                })
            .then((response) => {
                commit("updatePersonelList", response.result)
            });
        dispatch("getPersonelUnapprovedTotalPageCountFromServer");
    },
    initCompetitionPersonel({ commit }, payload) {
        PMSAxiosHelper
            .get("/api/personel/attendant", { params: payload })
            .then((response) => {
                commit("updateCompetitionPersonel", response.result)
            });
    },
    reportSingleUser({ commit }, payload) {
        PMSAxiosHelper.post("/api/reports/single_personel",{params: {id: payload.id,form:payload.form}}).then((response) => {
            commit("updateReportingPersonel",response.result)
        })
    },
    searchIndividualPersonel({ commit }, payload) {
        PMSAxiosHelper
            .get("/api/personel/search/individual", { params: { ssn: payload.ssn } })
            .then((response) => {
                commit("updateIndividualPersonel", response.result)
            });
    },
    initClubCompetitionPersonel({ commit }, payload) {
        PMSAxiosHelper
            .get("/api/personel/clubAttendant/" + payload.clubId, { params: payload }).then((response) => {
                commit(
                    "updateClubCompetitionPersonel",
                    getFilteredClubMembers(response.result)
                );
            })
        function getFilteredClubMembers(members) {
            const person = { male: [], female: [] };
            members.data.forEach((el) => {
                person[el.gender].push({
                    title: el.name + " " + el.surname,
                    value: el.id,
                });
            });
            return [
                { title: "Seçin", id: "" },
                { label: "Erkek", options: person.male },
                { label: "Kadın", options: person.female },
            ];
        }
    },
    initPersonelImage({ commit }, payload) {
        PMSAxiosHelper
            .get("/api/personel/picture/" + payload)
            .then((response) => {
                commit("updatePersonelImage", response);
            });
    },
    initPersonelVisas({ commit }, payload) {
        PMSAxiosHelper
            .get("/api/personel/visas/" + payload)
            .then((response) => {
                commit("updateVisas", response.result);
            });
    },
    updatePersonel(_, payload) {
        const formData = new FormData();
        const photo = payload.pictureAddress;
        delete payload.pictureAddress;
        payload.pictureAddress = photo;
        if (!(payload.pictureAddress instanceof File)) {
            delete payload.pictureAddress;
        }
        PMSAxiosHelper.buildFormData(formData, payload);
        PMSAxiosHelper
            .put("/api/personel/" + payload.id, formData)

        store.dispatch("notification/setNotifications", [{ title: "Personel güncellendi!", body: "Personel başarıyla güncellendi", type: "success" }]);
    },
    savePersonel({ dispatch }, payload) {
        const formData = new FormData();
        const photo = payload.pictureAddress;
        delete payload.pictureAddress;
        payload.pictureAddress = photo;
        if (!(payload.pictureAddress instanceof File)) {
            delete payload.pictureAddress;
        }
        PMSAxiosHelper.buildFormData(formData, payload);
        PMSAxiosHelper
            .post("/api/personel/add", formData)
            .then(() => {
                dispatch("initPersonel");
            });
        store.dispatch("notification/setNotifications", [{ title: "Personel eklendi!", body: "Personel başarıyla eklendi", type: "success" }]);
    },
    registerPersonel({ commit }, payload) {
        PMSAxiosHelper
            .post("/api/personel/register", payload)
        commit("clearPersonelList");
        store.dispatch("notification/setNotifications", [{ title: "Kaydınız gönderildi!", body: "Kayıt isteğiniz gönderilmiştir. Kayıt isteğiniz onaylandığı zaman mail adresinize bildirim gelecektir.", type: "success" }]);
    },
    getPersonelTotalPageCountFromServer({ commit },) {
        PMSAxiosHelper
            .get("/api/personel/getAllItemsCount",
                {
                    params: {
                        where: state.filter.where ?? "",
                        region: state.filter.region == "" ? null : state.filter.region,
                        role: state.filter.role ?? "",
                    }
                })
            .then((response) => {
                commit("updatePersonelTotalPageCount", Math.ceil(response.result / constants.MAX_TABLE_ITEMS))
            });
    },
    getPersonelUnapprovedTotalPageCountFromServer({ commit },) {
        PMSAxiosHelper
            .get("/api/personel/getAllItemsCount",
                {
                    params: {
                        approved: 1
                    }
                })
            .then((response) => {
                commit("updatePersonelTotalPageCount", Math.ceil(response.result / constants.MAX_TABLE_ITEMS))
            });
    },
    deletePersonel({ dispatch }, payload) {
        PMSAxiosHelper
            .delete("/api/personel/deleteItems/", payload)
            .then(() => {
                dispatch("initPersonel")
            });
        store.dispatch("notification/setNotifications", [{ title: "Personel silindi!", body: "Personel başarıyla silindi", type: "success" }]);
    },
    approvePersonel({ dispatch }, payload) {
        PMSAxiosHelper
            .put("/api/personel/approve/", payload)
            .then(() => {
                dispatch("initPersonel")
            });
        store.dispatch("notification/setNotifications", [{ title: "Personel onaylandı!", body: "Personel başarıyla onaylandı", type: "success" }]);
    },

    visaPersonel({ dispatch }, payload) {
        PMSAxiosHelper
            .post("/api/personel/visa", payload)
            .then(() => {
                dispatch("initpersonelAddresItem", payload.personelId);
                dispatch("initPersonelVisas", payload.personelId);
            });
    },
    deleteVisa({ dispatch }, payload) {
        PMSAxiosHelper
            .delete("/api/personel/visa/" + payload.id)
            .then(() => {
                dispatch("initpersonelAddresItem", payload.personelId);
                dispatch("initPersonelVisas", payload.personelId);
            });
    },
    deleteUnapprovedPersonel({ dispatch }, payload) {
        PMSAxiosHelper
            .delete("/api/personel/deleteItems/", payload)
            .then(() => {
                dispatch("initUnapprovedPersonel")
            });
    },
    approveUnapprovedPersonel({ dispatch }, payload) {
        PMSAxiosHelper
            .put("/api/personel/approve/", payload)
            .then(() => {
                dispatch("initUnapprovedPersonel")
            });
    },
    initPersonelAddressIdList({ commit, dispatch }, payload) {
        PMSAxiosHelper
            .get("/api/personel/getAllIds/", {
                params: {
                    filter: payload ?? "",
                    where: state.filter.where ?? "",
                    role: state.filter.role ?? "",
                }
            })
            .then((response) => {
                if (response.result.count > 0) {
                    dispatch("initpersonelAddresItem", response.result.rows[0].id)
                    dispatch("initPersonelImage", response.result.rows[0].id)
                }
                commit("updatePersonelAddressIdList", response.result)
            });
    },
    initpersonelAddresItem({ commit }, payload) {
        PMSAxiosHelper
            .get("/api/personel/" + payload)
            .then((response) => {
                commit("updatePersonelAddresItem", response.result)
            });
    },
    getMessagePersonelFromServer({ commit }, payload) {
        PMSAxiosHelper
            .post("/api/personel/", { where: payload.where })
            .then((response) => {
                commit("updateMessagePersonel", response.result)
            });
    },
}

export default {
    state,
    getters,
    mutations,
    actions
}
