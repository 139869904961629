import PMSAxiosHelper from '../helpers/PMSAxiosHelper';
import store from "../index.js";

const state = {
    matches: [],
    matchFilter: {
        gender: "",
        category: "",
        boatType: "",
        distance: ""
    },
    matchFilterOptions: {
        gender: [],
        category: [],
        boatType: [],
        distance: [],
    }
}

const getters = {
    getMatches(state) {
        return state.matches;
    },
    getMatchFilter(state) {
        return state.matchFilter;
    },
    getMatchFilterOptions(state) {
        return state.matchFilterOptions;
    },
}

const mutations = {
    updateMatches(state, matches) {
        state.matches = matches;
    },
    updateMatchFilter(state, matchFilter) {
        state.matchFilter = matchFilter;
    },
    updateMatchFilterOptions(state, matchFilterOptions) {
        state.matchFilterOptions = matchFilterOptions;
    },
    clearMatches(state) {
        state.matches = [];
    }
}

const actions = {
    async initMatches({ commit }, payload) {
        return await PMSAxiosHelper
            .get("/api/match/" + payload.competitionId, { params: state.matchFilter })
            .then((response) => {
                commit("updateMatches", response.result);
            });
    },
    drawMatches({ dispatch }, payload) {
        return PMSAxiosHelper
            .post("/api/match/draw/" + payload.competitionId, payload)
            .then(() => {
                dispatch("initMatches", payload);
            });
    },
    getMatchFilterOptionsFromServer({ commit }, payload) {
        PMSAxiosHelper
            .get("/api/match/filter/" + payload.competitionId)
            .then((response) => {
                commit("updateMatchFilterOptions", response.result);
            });
    },
    editMatches({ dispatch }, payload) {
        PMSAxiosHelper
            .put("/api/match/", payload.matches)
            .then(() => {
                dispatch("initMatches", payload);
                store.dispatch("notification/setNotifications", [{ title: "Değişiklikler kaydedildi!", body: "Değişiklikler başarı ile kaydedildi!", type: "success" }]);
            });
    },
    deleteLastDraw({ dispatch }, payload) {
        PMSAxiosHelper
            .delete("/api/match/last/" + payload.competitionId)
            .then(() => {
                dispatch("initMatches", payload);
            });
    },
}


export default {
    state,
    getters,
    mutations,
    actions
}

