import PMSAxiosHelper from '../helpers/PMSAxiosHelper';
import constants from '../constants';
import store from "../index";
const state = {
    messages: [],
    filter: null,
    messageTotalPageCount: 1,
    singleMessage:{},
    unreadMessageCount: 0,
    messageFields: [
        {
            key: "selected",
            label: "Seç",
        },
        {
            key: "id",
            label: "Sıra No",
        },
        {
            key: "title",
            label: "Konu",
        },
        {
            key: "message",
            label: "Mesaj",
        },
        {
            key: "sendername",
            label: "Kimden",
        },
        {
            key: "receivername",
            label: "Kime",
        },
        {
            key: "hasRead",
            label: "Okundu",
        },
    ],
}

const getters = {
    getMessages(state) {
        return state.messages;
    },
    getMessageFields(state) {
        return state.messageFields;
    },
    getMessageTotalPageCount(state) {
        return state.messageTotalPageCount;
    },
    getUnreadMessageCount(state) {
        return state.unreadMessageCount;
    },
    getMessageFilter(state) {
        return state.filter;
    },
    getSingleMessage(state){
        return state.singleMessage;
    }
}

const mutations = {
    updateMessageList(state, message) {
        state.messages = message;
    },
    addToMessageList(state, message) {
        state.messages.push(message);
    },
    clearMessageList(state) {
        state.messages.clear();
    },
    updateMessageTotalPageCount(state, count) {
        state.messageTotalPageCount = count;
    },
    updateUnreadMessageCount(state, count) {
        state.unreadMessageCount = count;
    },
    updateMessageFilter(state, newFilter) {
        state.filter = newFilter;
    },
    updateSingleMessage(state,message){
        state.singleMessage = message;
    }

}

const actions = {
    initMessages({ commit, dispatch }, payload) {
        var currentPage = payload.page ?? 1;
        PMSAxiosHelper
            .get("/api/message",
                {
                    params:
                    {
                        id: payload.id,
                        lim: constants.MAX_TABLE_ITEMS,
                        off: constants.MAX_TABLE_ITEMS * (currentPage - 1),
                        message: state.filter ?? ""
                    }
                })
            .then((response) => {
                commit("updateMessageList", response.result);
            });
        dispatch("getMessageTotalPageCountFromServer", payload);
    },
    sendMessage({ dispatch }, payload) {
        PMSAxiosHelper
            .post("/api/message/send", payload)
            .then(() => {
                dispatch("initMessages", {id:payload.from})
                
            });
            store.dispatch("notification/setNotifications", [{title:"Mesajınız gönderildi!",body:"Mesajınız başarıyla gönderildi",type:"success"}]);
    },
    getMessageTotalPageCountFromServer({ commit }, payload) {
        PMSAxiosHelper
            .get("/api/message/getAllItemsCount", { params: { message: state.filter ?? "", id: payload.id } })
            .then((response) => {
                commit("updateMessageTotalPageCount", Math.ceil(response.result / constants.MAX_TABLE_ITEMS));
            });
    },
    getUnreadMessageCountFromServer({ commit }, payload) {
        PMSAxiosHelper
            .get("/api/message/getUnreadMessageCount/" + payload.id)
            .then((response) => {
                commit("updateUnreadMessageCount", response.result);
            });
    },
    deleteMessages({ dispatch }, payload) {
        PMSAxiosHelper
            .delete("/api/message/deleteItems", payload.ids)
            .then(() => {
                dispatch("initMessages", payload)
            });
            store.dispatch("notification/setNotifications", [{title:"Mesajlar silindi!",body:"Mesajlar başarıyla silindi",type:"success"}]);
            
    },
    seeMessage({ commit, dispatch }, payload) {
        PMSAxiosHelper
            .post("/api/message/seeItem/" + payload.ids,{})
            .then(() => {
                commit("updateUnreadMessageCount", state.unreadMessageCount - 1);
                dispatch("initMessages", payload)
            });
    },
    initSingleMessage({ commit }, payload) {
        PMSAxiosHelper
            .get("/api/message/"+payload.userId+"/"+payload.messageId)
            .then((response) => {
                commit("updateSingleMessage", response.result)
            });
    },
    

}


export default {
    state,
    getters,
    mutations,
    actions
}

