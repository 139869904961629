<template>
  <router-view />
</template>
<script>
export default {
  created() {
    this.$store.dispatch("initAuth");
  },
  watch: {
    $route() {
      this.$store.dispatch("notification/setError", null);
      this.$store.dispatch("notification/setNotifications", []);
    },
  },
};
</script>
<style>
html{
  height: 100%;
  margin: 0;
  padding: 0;
  background: #ddd;
  font-size: 0.8rem;
}
body{
  height: 100%;
}
</style>