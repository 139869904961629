import PMSAxiosHelper from '../helpers/PMSAxiosHelper';
import constants from '../constants';
import store from '..';

const state = {
    clubs: [],
    clubTrainers: [],
    clubMembers: [],
    clubNames: [],
    images: {
        image: "",
        teamImage: "",
    },
    clubTotalPageCount: 1,
    filter: "",
    singleClub: {},
    accreditations: [],
    clubFields: [
        {
            key: "selected",
            label: "Seç",
        },
        {
            key: "id",
            label: "Sıra No",
        },
        {
            key: "clubName",
            label: "Ad",
        },
        {
            key: "minister",
            label: "Başkan",
        },
        {
            key: "secretary",
            label: "Sekreter",
        },
        {
            key: "events",
            label: "İşlemler",
        },
    ],

}

const getters = {
    getClubs(state) {
        return state.clubs;
    },
    getClubTrainers(state) {
        return state.clubTrainers;
    },
    getClubMembers(state) {
        return state.clubMembers;
    },
    getClubFields(state) {
        return state.clubFields;
    },
    getClubImage(state) {
        return state.images.image;
    },
    getClubTeamImage(state) {
        return state.images.teamImage;
    },
    getClubAccreditations(state) {
        return state.accreditations;
    },
    getClubMemberFields(state) {
        return state.clubMemberFields;
    },
    getClubTotalPageCount(state) {
        return state.clubTotalPageCount;
    },
    getClubNames(state) {
        return state.clubNames;
    },
    getClubFilter(state) {
        return state.filter;
    },
    getSingleClub(state) {
        return state.singleClub;
    }
}

const mutations = {
    updateClubList(state, club) {
        state.clubs = club;
    },
    updateClubNames(state, clubs) {
        return state.clubNames = clubs;
    },
    clearClubList(state) {
        state.clubs.clear();
    },
    updateClubMembers(state, members) {
        state.clubTrainers = members.filter(element => element.branch.trainerState);
        state.clubMembers = members.filter(element => element.branch.sportState);
    },
    updateClubImage(state, image) {
        state.images.image = image.photo;
        state.images.teamImage = image.teamPhoto;
    },
    updateAccreditations(state, accreditations) {
        state.accreditations = accreditations;
    },
    updateClubTotalPageCount(state, count) {
        state.clubTotalPageCount = count;
    },
    updateClub(state, club) {
        state.clubs = state.clubs.map(element => element.id == club.id ? club : element);
    },
    updateClubFilter(state, newFilter) {
        state.filter = newFilter;
    },
    updateSingleClub(state, newClub) {
        state.singleClub = newClub;
    }
}

const actions = {
    initClubs({ commit, dispatch }, payload) {
        if (store.getters.checkToken.isFederation) {
            PMSAxiosHelper
                .get("/api/club/",
                    {
                        params:
                        {
                            lim: constants.MAX_TABLE_ITEMS,
                            off: constants.MAX_TABLE_ITEMS * ((payload ?? 1) - 1), // if page is not given then get first page
                            where: state.filter ?? ""
                        }
                    })
                .then((response) => {
                    commit("updateClubList", response.result);
                });


            dispatch("getClubTotalPageCountFromServer", payload);
        }
    },
    initClubImages({ commit }, payload) {
        PMSAxiosHelper
            .get("/api/club/photo/" + payload)
            .then((response) => {
                commit("updateClubImage", response);
            });
    },
    initClubAccreditations({ commit }, payload) {
        PMSAxiosHelper
            .get("/api/club/accreditation/" + payload)
            .then((response) => {
                commit("updateAccreditations", response.result);
            });
    },
    accreditateClub({ dispatch }, payload) {
        PMSAxiosHelper
            .post("/api/club/accreditation", payload)
            .then(() => {
                dispatch("initSingleClub", payload.clubId);
                dispatch("initClubAccreditations", payload.clubId);
            });
    },
    deleteAccreditation({ dispatch }, payload) {
        PMSAxiosHelper
            .delete("/api/club/accreditation/" + payload.id)
            .then(() => {
                dispatch("initSingleClub", payload.clubId);
                dispatch("initClubAccreditations", payload.clubId);
            });
    },
    saveClub({ dispatch }, payload) {
        const formData = new FormData();
        const photo = payload.photoAddress;
        const team = payload.teamPhotoAddress;
        delete payload.photoAddress;
        delete payload.teamPhotoAddress;
        payload.photoAddress = photo;
        payload.teamPhotoAddress = team;
        if (!(payload.photoAddress instanceof File)) {
            delete payload.photoAddress;
        }
        if (!(payload.teamPhotoAddress instanceof File)) {
            delete payload.teamPhotoAddress;
        }
        PMSAxiosHelper.buildFormData(formData, payload);
        PMSAxiosHelper
            .post("/api/club/add", formData)
            .then(() => {
                dispatch("initClubs")
            });
        store.dispatch("notification/setNotifications", [{ title: "Kulüp kaydedildi!", body: "Kulüp başarıyla kaydedildi", type: "success" }]);
    },
    getClubTotalPageCountFromServer({ commit }) {

        PMSAxiosHelper
            .get("/api/club/getAllItemsCount", { params: { where: state.filter } })
            .then((response) => {
                commit("updateClubTotalPageCount", Math.ceil(response.result / constants.MAX_TABLE_ITEMS));
            });
    },

    initClubMembers({ commit }, payload) {
        PMSAxiosHelper
            .get("/api/personel/clubMembers/" + payload,)
            .then((response) => {
                commit("updateClubMembers", response.result);
            });
    },

    updateClub({ dispatch }, payload) {
        const formData = new FormData();
        const photo = payload.photoAddress;
        const team = payload.teamPhotoAddress;
        delete payload.photoAddress;
        delete payload.teamPhotoAddress;
        payload.photoAddress = photo;
        payload.teamPhotoAddress = team;
        if (!(payload.photoAddress instanceof File)) {
            delete payload.photoAddress;
        }
        if (!(payload.teamPhotoAddress instanceof File)) {
            delete payload.teamPhotoAddress;
        }
        PMSAxiosHelper.buildFormData(formData, payload);
        PMSAxiosHelper.put("/api/club/" + payload.id, formData).then(() => {
            dispatch("initClubs");
        });
        store.dispatch("notification/setNotifications", [{ title: "Kulüp güncellendi!", body: "Kulüp başarıyla güncellendi", type: "success" }]);
    },
    deleteClubs({ dispatch }, payload) {
        PMSAxiosHelper
            .delete("/api/club/deleteItems/", payload)
            .then(() => {
                dispatch("initClubs")
            });
    },
    async getClubNames({ commit }) {
        return PMSAxiosHelper
            .get("/api/club/getAllClubNames/")
            .then((response) => {
                commit("updateClubNames", response.result)
                return response;
            });
    },
    initSingleClub({ commit }, payload) {
        PMSAxiosHelper.get("/api/club/" + payload)
            .then((response) => {
                commit("updateSingleClub", response.result)
            });
    },
    deletePersonelFromClub({ dispatch }, payload) {
        PMSAxiosHelper.post("/api/personel/deletePersonelClub/" + payload.id, {}).then(() => {
            dispatch("initClubMembers", payload.clubId)
        });
        store.dispatch("notification/setNotifications", [{ title: "Sporcu çıkarıldı!", body: "Sporcu kulüpten başarıyla çıkarıldı", type: "success" }]);
    }
}


export default {
    state,
    getters,
    mutations,
    actions
}

