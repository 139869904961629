import PMSAxiosHelper from '../helpers/PMSAxiosHelper';
import router from '../../router';
import jwtDecode from 'jwt-decode';
import store from "../index.js";

const state = {
    token: null,
    user: null
}

const getters = {
    getToken(state) {
        return state.token;
    },
    checkToken(state) {
        if (state.token && state.user) {
            if (state.user.roles.federation == "active") {
                return {
                    authenticated: true,
                    isFederation: true
                }
            } else if (state.user.roles.provincialRepresentative == "active") {
                return {
                    authenticated: true,
                    isProvincialRepresentative: true
                }
            } else if (state.user.roles.club == "active") {
                return {
                    authenticated: true,
                    isClub: true
                }
            } else if (state.user.roles.individual == "active") {
                return {
                    isIndividual: true,
                    authenticated: true,

                }
            }else if (state.user.roles.sportsReferee == "active") {
                return {
                    sportsReferee: true,
                    authenticated: true,

                }
            }  else {
                return {
                    authenticated: true,
                    isPersonel: true
                }
            }
        } else {
            return {
                authenticated: false
            }
        }
    },
    getUser(state) {
        return state.user;
    }
}

const mutations = {
    updateToken(state, token) {
        state.token = token;
        state.user = jwtDecode(token);
        localStorage.setItem("token", token);
    },
    clearToken(state) {
        state.token = null;
        state.user = null;
    }
}

const actions = {
    initAuth({ commit, dispatch }) {
        let token = localStorage.getItem("token");
        if (token) {
            try {
                var decoded = jwtDecode(token);
                dispatch("setTimeOutTimer", decoded.exp - Date.now() / 1000);
                commit("updateToken", token);
            } catch (e) {
                router.push("/login");
            }
        }
    },
    login({ commit, dispatch }, payload) {
        return PMSAxiosHelper
            .post("/api/auth/login", payload)
            .then((response) => {
                if (!response.name) {
                    commit("updateToken", response);
                    var decoded = jwtDecode(response);
                    dispatch("setTimeOutTimer", decoded.exp - Date.now() / 1000);
                }
            });
    },
    async logout({ commit },) {
        commit("clearToken");
        localStorage.removeItem("token");
        await router.replace("/login");
        location.reload();
    },
    forgotPassword(_, payload) {
        PMSAxiosHelper
            .post("/api/auth/forgotPassword", payload)
            .then(() => {
                store.dispatch("notification/setNotifications", [{ title: "Başarılı!", body: "Parola sıfırlama bağlantısı e-posta adresinize gönderildi!", type: "success" }]);
            });
    },
    validatePasswordHash(_, payload) {
        return PMSAxiosHelper.post("/api/auth/validateHash", payload);
    },
    changePassword(_, payload) {
        PMSAxiosHelper.post("/api/auth/changePassword", payload).then(() => {
            store.dispatch("notification/setNotifications", [{ title: "Başarılı!", body: "Parolanız başarıyla değiştirilmiştir!", type: "success" }]);
        })
    },
    setTimeOutTimer({ dispatch }, expiresIn) {
        setTimeout(() => {
            dispatch("logout");
        }, expiresIn * 1000);
    }
}


export default {
    state,
    getters,
    mutations,
    actions
}

