import PMSAxiosHelper from '../helpers/PMSAxiosHelper';
import store from "../index";
const state = {
    federationItems: {},
    faqItems: "",
    popupImage: "",
    scrollingText: [""],
}

const getters = {
    getFederationItems(state) {
        return state.federationItems;
    },
    getPopupImage(state) {
        return state.popupImage;
    },
    getFaqItems(state) {
        return state.faqItems;
    },
    getScrollingText(state) {
        return state.scrollingText;
    }

}

const mutations = {
    updateFederationItems(state, federationItem) {
        state.federationItems = federationItem;
    },
    updatePopupImage(state, image) {
        state.popupImage = image;
    },
    updateFaqItems(state, item) {
        state.faqItems = item;
    },
    updateScrollingText(state, item) {
        state.scrollingText = item;
    }
}

const actions = {
    initFederation({ commit },) {
        PMSAxiosHelper
            .get("/api/federation/",)
            .then((response) => {
                commit("updateFederationItems", response.result);
            });
    },
    initFaq({ commit }, payload) {
        PMSAxiosHelper
            .get("/api/federation/" + payload)
            .then((response) => {
                commit("updateFaqItems", response.result);
            });
    },
    initScrollingText({ commit }) {
        PMSAxiosHelper
            .get("/api/federation/scrollingText")
            .then((response) => {
                var parser = new DOMParser();
                var doc = parser.parseFromString(response.result, 'text/html');
                commit("updateScrollingText", Array.from(doc.getElementsByTagName("li")).map((e) => e.textContent));
            });
    },
    initPopupImage({ commit }) {
        PMSAxiosHelper
            .get("/api/federation/popup")
            .then((response) => {
                commit("updatePopupImage", response);
            });
    },
    editFederation({ dispatch }, payload) {
        const formData = new FormData();
        const photo = payload.popupWindow;
        delete payload.popupWindow;
        payload.popupWindow = photo;
        if (!(payload.popupWindow instanceof File)) {
            delete payload.popupWindow;
        }
        PMSAxiosHelper.buildFormData(formData, payload);
        return PMSAxiosHelper
            .put("/api/federation/", formData)
            .then(() => {
                dispatch("initFederation");
                store.dispatch("notification/setNotifications", [{title:"Federasyon bilgileri güncellendi!",body:"Federasyon bilgileri başarıyla güncellendi",type:"success"}]);
            });

            
    }

}


export default {
    state,
    getters,
    mutations,
    actions
}

