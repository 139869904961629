import PMSAxiosHelper from '../helpers/PMSAxiosHelper';

const state = {
    mapItems:{
        totalPersonelCount: 0,
        totalApprovedPersonelCount: 0,
        totalUnapprovedPersonelCount: 0,
        totalSports: 0,
        totalTrainer: 0,
        totalReferee: 0,
        totalprovincialRepresentative: 0,
        totalCompetition: 0,
        totalClub: 0,
        sportCountByCity:[],
        trainerCountByCity:[],
        refereeCountByCity:[],
        stoppedSportCountByCity:[],
        stoppedTrainerCountByCity:[],
        stoppedRefereeCountByCity:[]
    }
}

const getters = {
    getMapItems(state) {
        return state.mapItems;
    },

}

const mutations = {
    updateMapItems(state, mapItems) {
        state.mapItems = mapItems;
    },
}

const actions = {
    initMapValues({ commit },) {
        PMSAxiosHelper
            .get("/api/map/",)
            .then((response) => {
                commit("updateMapItems", response.result);
            });
    },
}


export default {
    state,
    getters,
    mutations,
    actions
}

