
const state = {
    error: null,
    notifications:[]
}

const getters = {
    getError(state) {
        return state.error;
    },
    getNotifications(state){
        return state.notifications;
    }
}

const mutations = {
    updateError(state, payload) {
        state.error = payload;
    },
    updateNotifications(state,payload){
        state.notifications = payload;
    },
    addNotification(state,notification){
        state.notifications.push(notification)
    }
}

const actions = {
    setError({ commit }, payload) {
        commit("updateError", payload);
    },
    setNotifications({ commit }, payload) {
        commit("updateNotifications", payload);
    },
}


export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}