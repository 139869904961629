import vue from "vue";
import store from "../"
async function request(type, path, params) {

  try {
    let response;
    const token = localStorage.getItem("token");

    switch (type) {
      case "get":
        response = await vue.axios.get(path, { headers: token ? { "x-access-token": token } : null, ...(params ?? null) },);
        break;
      case "post":
        response = await vue.axios.post(path, params ?? null, { headers: { "x-access-token": token, 'Content-Type': params instanceof FormData ? 'multipart/form-data' : 'application/json' } });
        break;
      case "put":
        response = await vue.axios.put(path, params ?? null, { headers: { "x-access-token": token, 'Content-Type': params instanceof FormData ? 'multipart/form-data' : 'application/json' } });
        break;
      case "delete":
        response = await vue.axios.delete(path, { headers: token ? { "x-access-token": token } : null, data: params ?? null });
        break;
    }
    if (response.name) {
      store.dispatch("notification/setError", response.response.data.message, { root: true });
      
      return response;
    } else {
      return response.data;
    }
  } catch (e) {
      
    store.dispatch("notification/setError", e, { root: true });
    
  }
}

function buildFormData(formData, data, parentKey) {
  if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
    Object.keys(data).forEach(key => {
      buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
    });
  } else {
    const value = data == null ? '' : data;
    formData.append(parentKey, value);
  }
}

export default {
  async get(path, params) {
    return request("get", path, params);
  },
  async put(path, params) {
    return request("put", path, params);
  },
  async post(path, params) {
    return request("post", path, params);
  },
  async delete(path, params) {
    return request("delete", path, params);
  },
  buildFormData
}