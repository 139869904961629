import PMSAxiosHelper from '../helpers/PMSAxiosHelper';
import constants from '../constants';
import store from "../index.js";
const state = {
    competitionGroups: [],
    singleCompetitionGroup: { formOfParticipation: "", category: "", boatType: "", distance: "", club: {} },
    competitionGroupTotalPageCount: 1,
    currentPage: 1,
    competitionGroupFilter: ""
}

const getters = {
    getCompetitionGroups(state) {
        return state.competitionGroups;
    },
    getSingleCompetitionGroup(state) {
        return state.singleCompetitionGroup;
    },
    getCompetitionGroupFilter(state) {
        return state.competitionGroupFilter;
    },
    getCompetitionGroupTotalPageCount(state) {
        return state.competitionGroupTotalPageCount;
    },
}

const mutations = {
    updateCompetitionGroups(state, competitionGroups) {
        state.competitionGroups = competitionGroups;
    },
    updateSingleCompetitionGroup(state, competitionGroup) {
        state.singleCompetitionGroup = competitionGroup;
    },
    updateCompetitionGroupFilter(state, competitionGroupFilter) {
        state.competitionGroupFilter = competitionGroupFilter;
    },
    updateCompetitionGroupTotalPageCount(state, competitionGroupTotalPageCount) {
        state.competitionGroupTotalPageCount = competitionGroupTotalPageCount;
    },
    clearCompetitionGroups(state) {
        state.competitionGroups = [];
    }
}

const actions = {
    initClubCompetitionGroups({ commit }, payload) {
        PMSAxiosHelper
            .get("/api/competitionGroup/" + payload.competitionId, { params: { id: payload.clubId } })
            .then((response) => {

                commit("updateCompetitionGroups", response.result);
            });
    },
    initPersonelCompetitionGroups({ commit }, payload) {
        PMSAxiosHelper
            .get("/api/competitionGroup/personel/" + payload)
            .then((response) => {
                commit("updateCompetitionGroups", response.result);
            });
    },
    initSingleCompetitionGroup({ commit }, payload) {
        PMSAxiosHelper
            .get("/api/competitionGroup/group/" + payload.groupId)
            .then((response) => {
                commit("updateSingleCompetitionGroup", response.result);
            })
    },
    initCompetitionGroups({ commit, dispatch }, payload) {
        var currentPage = payload.currentPage ?? 1;
        PMSAxiosHelper
            .get("/api/competitionGroup", { params: { lim: 10, off: (currentPage - 1) * 10, id: payload.competitionId, where: state.competitionGroupFilter } })
            .then((response) => {
                commit("updateCompetitionGroups", response.result);
            });
        dispatch("getCompetitionGroupCountFromServer", payload);
    },
    getCompetitionGroupCountFromServer({ commit }, payload) {
        PMSAxiosHelper
            .get("/api/competitionGroup/getAllItemsCount", { params: { id: payload.competitionId, where: state.competitionGroupFilter } })
            .then((response) => {
                commit("updateCompetitionGroupTotalPageCount", Math.ceil(response.result / constants.MAX_TABLE_ITEMS));
            });
    },
    addCompetitionGroup(_, payload) {
        PMSAxiosHelper
            .post("/api/competitionGroup/add/" + payload.clubId, payload.items).then(() => store.dispatch("notification/setNotifications", [{ title: "Gruplar eklendi!", body: "Gruplar başaıyla eklendi", type: "success" }]))

    },
    addFederationCompetitionGroup(_, payload) {
        return PMSAxiosHelper
            .post("/api/competitionGroup/federation/add", payload);

    },
    deleteCompetitionGroup({ dispatch }, payload) {
        PMSAxiosHelper
            .delete("/api/competitionGroup/deleteItems/" + payload.params.competitionId + "/" + payload.params.clubId, payload.items)
            .then(() => {
                dispatch("initClubCompetitionGroups", payload.params);
                store.dispatch("notification/setNotifications", [{ title: "Personel silindi!", body: "Personel başaıyla silindi", type: "success" }]);
            });
    },
    deleteFederationCompetitionGroup({ dispatch }, payload) {
        PMSAxiosHelper
            .delete("/api/competitionGroup/deleteItems/" + payload.competitionId, payload.items)
            .then(() => {
                dispatch("initCompetitionGroups", payload);
                store.dispatch("notification/setNotifications", [{ title: "Personel silindi!", body: "Personel başaıyla silindi", type: "success" }]);
            });

    },
    editCompetitionGroup({ dispatch }, payload) {
        PMSAxiosHelper
            .put("/api/competitionGroup/" + payload.id, payload)
            .then(() => {
                dispatch("initClubCompetitionGroups");
            });
    },
    editCompetitionGroups(_, payload) {
        PMSAxiosHelper
            .put("/api/competitionGroup/", payload);
    }
}


export default {
    state,
    getters,
    mutations,
    actions
}

