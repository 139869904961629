import Vue from 'vue'
import VueCookie from 'vue-cookie';
import VueRouter from 'vue-router'
import store from "../store";


const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch((err) => {
    if (VueRouter.isNavigationFailure(err)) {
      // resolve err
      return err
    }
    // rethrow error
    return Promise.reject(err)
  })
}
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Landing',
    beforeEnter(from, to, next) {
      store.dispatch("initAuth");
      if (store.getters.checkToken.authenticated) {
        next("/dashboard")
      } else {
        next("/login")
      }
    }
  },
  {
    path: '/resetPassword/:hash',
    name: 'ResetPassword',
    component: () => import('../views/login/ResetPassword.vue'),
    beforeEnter: async (from, to, next) => {
      const hash = from.params.hash;
      const response = await store.dispatch("validatePasswordHash", { hash })
      if (response.token) {
        VueCookie.set("fp", response.token, "1h");
        next(true);
      } else {
        next("/");
      }
    },
  },
  {
    path: '/loginCommon',
    name: 'LoginCommon',
    component: () => import('../views/login/LoginCommon.vue'),
    children: [
      {
        path: '/password',
        name: 'Password',
        component: () => import('../views/login/Password.vue')
      },
      {
        path: '/login',
        name: 'Login',
        component: () => import('../views/login/Login.vue'),
        beforeEnter(from, to, next) {
          next()
        }
      },

      {
        path: '/register',
        name: 'Register',
        component: () => import('../views/login/Register.vue')
      }
    ],
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    beforeEnter(from, to, next) {
      store.dispatch("initAuth"); // DONT FORGET TO KEEP SESSION!!!!!!!!!!!!!!!!!!!!!
      if (from.path != "/dashboard" && to.path != "/dashboard") return next();
      if (store.getters.checkToken.authenticated) {
        if (store.getters.checkToken.isFederation) {
          next("/pending-approvals");
        } else if (store.getters.checkToken.isClub) {
          next({ name: 'ClubDetail', params: { id: store.getters.getUser.id } });
        } else {
          next({ name: "PersonelDetail", params: { id: store.getters.getUser.id } });
        }
      } else {
        next('/login');
      }
    },
    component: () => import('../views/dashboard/DashboardCommon.vue'),
    children: [
      {
        path: '/addClub',
        name: 'AddClub',
        beforeEnter(from, to, next) {
          store.dispatch("initAuth");
          if (store.getters.checkToken.authenticated && store.getters.checkToken.isFederation) {
            next()
          } else {
            store.dispatch("logout");
          }
        },
        component: () => import('../views/clubs/AddClub.vue')
      },
      {
        path: '/editClub/:id',
        name: 'EditClub',
        beforeEnter(from, to, next) {
          store.dispatch("initAuth");
          if (store.getters.checkToken.authenticated && (store.getters.checkToken.isFederation || store.getters.checkToken.isClub)) {
            next()
          } else {
            store.dispatch("logout");
          }
        },
        component: () => import('@/views/clubs/EditClub.vue')
      },
      {
        path: '/clubinfo/:id',
        name: 'ClubDetail',
        component: () => import('@/views/clubs/ClubDetail.vue')
      },
      {
        path: '/addCompetition',
        name: 'AddCompetition',
        beforeEnter(from, to, next) {
          store.dispatch("initAuth");
          if (store.getters.checkToken.authenticated && store.getters.checkToken.isFederation) {
            next()
          } else {
            store.dispatch("logout");
          }
        },
        component: () => import('@/views/competitions/AddCompetition.vue')
      },
      {
        path: '/addCompetitionGroup/:id',
        name: 'AddCompetitionGroup',
        beforeEnter(from, to, next) {
          store.dispatch("initAuth");
          if (store.getters.checkToken.authenticated && (store.getters.checkToken.isClub)) {
            next()
          } else {
            store.dispatch("logout");
          }
        },
        component: () => import('@/views/competitions/group/AddCompetitionGroup.vue')
      },
      {
        path: '/addFederationCompetitionGroup/:id',
        name: 'AddFederationCompetitionGroup',
        beforeEnter(from, to, next) {
          store.dispatch("initAuth");
          if (store.getters.checkToken.authenticated && (store.getters.checkToken.isFederation)) {
            next()
          } else {
            store.dispatch("logout");
          }
        },
        component: () => import('@/views/competitions/group/AddFederationCompetitionGroup.vue')
      },
      {
        path: '/addIndividualCompetitionGroup/:id',
        name: 'AddIndividualCompetitionGroup',
        beforeEnter(from, to, next) {
          store.dispatch("initAuth");
          if (store.getters.checkToken.authenticated && (store.getters.checkToken.isIndividual)) {
            next()
          } else {
            store.dispatch("logout");
          }
        },
        component: () => import('@/views/competitions/group/AddIndividualCompetitionGroup.vue')
      },
      {
        path: '/individualCompetitionGroupsList/:id',
        name: 'IndividualCompetitionGroupsList',
        beforeEnter(from, to, next) {
          store.dispatch("initAuth");
          if (store.getters.checkToken.authenticated && (store.getters.checkToken.isIndividual)) {
            next()
          } else {
            store.dispatch("logout");
          }
        },
        component: () => import('@/views/competitions/group/IndividualCompetitionGroupsList.vue')
      },

      {
        path: '/joinIndividualGroup/:id',
        name: 'JoinIndividualGroup',
        beforeEnter(from, to, next) {
          store.dispatch("initAuth");
          if (store.getters.checkToken.authenticated && (store.getters.checkToken.isIndividual)) {
            next()
          } else {
            store.dispatch("logout");
          }
        },
        component: () => import('@/views/competitions/group/JoinIndividualGroup.vue')
      },
      {
        path: '/federationIndividualGroupRequests/:id',
        name: 'FederationIndividualGroupRequests',
        beforeEnter(from, to, next) {
          store.dispatch("initAuth");
          if (store.getters.checkToken.authenticated && (store.getters.checkToken.isFederation)) {
            next()
          } else {
            store.dispatch("logout");
          }
        },
        component: () => import('@/views/competitions/group/FederationIndividualGroupRequests.vue')
      },
      {
        path: '/addCompetitionFederationGroup/:id',
        name: 'AddCompetitionFederationGroup',
        beforeEnter(from, to, next) {
          store.dispatch("initAuth");
          if (store.getters.checkToken.authenticated && store.getters.checkToken.isFederation) {
            next()
          } else {
            store.dispatch("logout");
          }
        },
        component: () => import('@/views/competitions/group/AddCompetitionFederationGroup.vue')
      },
      {
        path: '/clubCompetitionGroup/:id',
        name: 'ClubCompetitionGroup',
        beforeEnter(from, to, next) {
          store.dispatch("initAuth");
          if (store.getters.checkToken.authenticated && store.getters.checkToken.isClub) {
            next()
          } else {
            store.dispatch("logout");
          }
        },
        component: () => import('@/views/competitions/group/ClubCompetitionGroup.vue')
      },
      {
        path: '/editCompetition/:id',
        name: 'EditCompetition',
        beforeEnter(from, to, next) {
          store.dispatch("initAuth");
          if (store.getters.checkToken.authenticated && store.getters.checkToken.isFederation) {
            next()
          } else {
            store.dispatch("logout");
          }
        },
        component: () => import('@/views/competitions/EditCompetition.vue')
      },
      {
        path: '/federationGroups/:id',
        name: 'FederationGroups',
        beforeEnter(from, to, next) {
          store.dispatch("initAuth");
          if (store.getters.checkToken.authenticated && store.getters.checkToken.isFederation) {
            next()
          } else {
            store.dispatch("logout");
          }
        },
        component: () => import('@/views/competitions/group/FederationGroups.vue')
      },
      {
        path: '/groupDetail/:competitionId/:id',
        name: 'GroupDetail',
        beforeEnter(from, to, next) {
          store.dispatch("initAuth");
          if (store.getters.checkToken.authenticated) {
            next()
          } else {
            store.dispatch("logout");
          }
        },
        component: () => import('@/views/competitions/group/GroupDetail.vue')
      },
      {
        path: '/settings',
        name: 'Settings',
        beforeEnter(from, to, next) {
          store.dispatch("initAuth");
          if (store.getters.checkToken.authenticated && store.getters.checkToken.isFederation) {
            next()
          } else {
            store.dispatch("logout");
          }
        },
        component: () => import('../views/Settings.vue')
      },
      {
        path: '/management',
        name: 'Management',
        beforeEnter(from, to, next) {
          store.dispatch("initAuth");
          if (store.getters.checkToken.authenticated && store.getters.checkToken.isFederation) {
            next()
          } else {
            store.dispatch("logout");
          }
        },
        component: () => import('../views/Management.vue')
      },
      {
        path: '/faq',
        name: 'Faq',
        beforeEnter(from, to, next) {
          store.dispatch("initAuth");
          if (store.getters.checkToken.authenticated) {
            next()
          } else {
            store.dispatch("logout");
          }
        },
        component: () => import('../views/faq/Faq.vue')
      },
      {
        path: '/clubs',
        name: 'Clubs',
        beforeEnter(from, to, next) {
          store.dispatch("initAuth");
          if (store.getters.checkToken.authenticated && store.getters.checkToken.isFederation) {
            next()
          } else {
            store.dispatch("logout");
          }
        },
        component: () => import('../views/clubs/Clubs.vue')
      },
      {
        path: '/messages',
        name: 'Messages',
        beforeEnter(from, to, next) {
          store.dispatch("initAuth");
          if (store.getters.checkToken.authenticated && !store.getters.checkToken.isClub) {
            next()
          } else {
            store.dispatch("logout");
          }
        },
        component: () => import('../views/message/Messages.vue')
      },
      {
        path: '/sendMessage',
        name: 'SendMessage',
        beforeEnter(from, to, next) {
          store.dispatch("initAuth");
          if (store.getters.checkToken.authenticated && !store.getters.checkToken.isClub) {
            next()
          } else {
            store.dispatch("logout");
          }
        },
        component: () => import('../views/message/SendMessage.vue')
      },
      {
        path: '/messageDetail/:id',
        name: 'MessageDetail',
        beforeEnter(from, to, next) {
          store.dispatch("initAuth");
          if (store.getters.checkToken.authenticated && !store.getters.checkToken.isClub) {
            next()
          } else {
            store.dispatch("logout");
          }
        },
        component: () => import('../views/message/MessageDetail.vue')
      },
      {
        path: '/guidebook',
        name: 'GuideBook',
        beforeEnter(from, to, next) {
          store.dispatch("initAuth");
          if (store.getters.checkToken.authenticated && store.getters.checkToken.isFederation) {
            next()
          } else {
            store.dispatch("logout");
          }
        },
        component: () => import('../views/guidebook/GuideBook.vue')
      },
      {
        path: '/competitions',
        name: 'Competitions',
        beforeEnter(from, to, next) {
          store.dispatch("initAuth");
          if (store.getters.checkToken.authenticated) {
            next()
          } else {
            store.dispatch("logout");
          }
        },
        component: () => import('../views/competitions/Competitions.vue')
      },
      {
        path: '/editGroup/:competitionId/:id',
        name: 'EditGroup',
        beforeEnter(from, to, next) {
          store.dispatch("initAuth");
          if (store.getters.checkToken.authenticated && store.getters.checkToken.isFederation) {
            next()
          } else {
            store.dispatch("logout");
          }
        },
        component: () => import('../views/competitions/group/EditGroup.vue')
      },
      {
        path: '/matches/:id',
        name: 'Matches',
        beforeEnter(from, to, next) {
          store.dispatch("initAuth");
          if (store.getters.checkToken.authenticated && (store.getters.checkToken.isFederation || store.getters.checkToken.sportsReferee )) {
            next()
          } else {
            store.dispatch("logout");
          }
        },
        component: () => import('../views/match/Matches.vue')
      },
    
      {
        path: '/pending-approvals',
        name: 'PendingApprovals',
        beforeEnter(from, to, next) {
          store.dispatch("initAuth");
          if (store.getters.checkToken.authenticated && store.getters.checkToken.isFederation) {
            next()
          } else {
            store.dispatch("logout");
          }
        },
        component: () => import('../views/pending-approvals/PendingApprovals.vue')
      },
      {
        path: '/reports',
        name: 'Reports',
        beforeEnter(from, to, next) {
          store.dispatch("initAuth");
          if (store.getters.checkToken.authenticated && (store.getters.checkToken.isFederation || store.getters.checkToken.isProvincialRepresentative)) {
            next()
          } else {
            store.dispatch("logout");
          }
        },
        component: () => import('../views/reports/Reports.vue')
      },
      {
        path: '/trainerReport',
        name: 'TrainerReport',
        beforeEnter(from, to, next) {
          store.dispatch("initAuth");
          if (store.getters.checkToken.authenticated && (store.getters.checkToken.isFederation || store.getters.checkToken.isProvincialRepresentative)) {
            next()
          } else {
            store.dispatch("logout");
          }
        },
        component: () => import('../views/reports/TrainerReport.vue')
      },
      {
        path: '/refereeReport',
        name: 'RefereeReport',
        beforeEnter(from, to, next) {
          store.dispatch("initAuth");
          if (store.getters.checkToken.authenticated && (store.getters.checkToken.isFederation || store.getters.checkToken.isProvincialRepresentative)) {
            next()
          } else {
            store.dispatch("logout");
          }
        },
        component: () => import('../views/reports/RefereeReport.vue')
      },
      {
        path: '/sportReport',
        name: 'SportReport',
        beforeEnter(from, to, next) {
          store.dispatch("initAuth");
          if (store.getters.checkToken.authenticated && (store.getters.checkToken.isFederation || store.getters.checkToken.isProvincialRepresentative)) {
            next()
          } else {
            store.dispatch("logout");
          }
        },
        component: () => import('../views/reports/SportReport.vue')
      },
      {
        path: '/clubReport',
        name: 'ClubReport',
        beforeEnter(from, to, next) {
          store.dispatch("initAuth");
          if (store.getters.checkToken.authenticated && (store.getters.checkToken.isFederation || store.getters.checkToken.isProvincialRepresentative)) {
            next()
          } else {
            store.dispatch("logout");
          }
        },
        component: () => import('../views/reports/ClubReport.vue')
      },
      {
        path: '/clubReportSelect',
        name: 'ClubReportSelect',
        beforeEnter(from, to, next) {
          store.dispatch("initAuth");
          if (store.getters.checkToken.authenticated && (store.getters.checkToken.isFederation || store.getters.checkToken.isProvincialRepresentative)) {
            next()
          } else {
            store.dispatch("logout");
          }
        },
        component: () => import('../views/reports/ClubReportSelect.vue')
      },
      {
        path: '/advancedReports',
        name: 'AdvancedReports',
        beforeEnter(from, to, next) {
          store.dispatch("initAuth");
          if (store.getters.checkToken.authenticated && store.getters.checkToken.isFederation) {
            next()
          } else {
            store.dispatch("logout");
          }
        },
        component: () => import('../views/reports/advanced/AdvancedReports.vue')
      },
      {
        path: '/advancedReport',
        name: 'AdvancedReport',
        beforeEnter(from, to, next) {
          store.dispatch("initAuth");
          if (store.getters.checkToken.authenticated && store.getters.checkToken.isFederation) {
            next()
          } else {
            store.dispatch("logout");
          }
        },
        component: () => import('../views/reports/advanced/AdvancedReport.vue')
      },
      {
        path: '/advancedReport/:id',
        name: 'AdvancedReport',
        beforeEnter(from, to, next) {
          store.dispatch("initAuth");
          if (store.getters.checkToken.authenticated && store.getters.checkToken.isFederation) {
            next()
          } else {
            store.dispatch("logout");
          }
        },
        component: () => import('../views/reports/advanced/AdvancedReport.vue')
      },
      {
        path: '/personel',
        name: 'Personel',
        beforeEnter(from, to, next) {
          store.dispatch("initAuth");
          if (store.getters.checkToken.authenticated && store.getters.checkToken.isFederation) {
            next()
          } else {
            store.dispatch("logout");
          }
        },
        component: () => import('../views/personel/Personel.vue')
      },
      {
        path: '/addPersonel',
        name: 'AddPersonel',
        beforeEnter(from, to, next) {
          store.dispatch("initAuth");
          if (store.getters.checkToken.authenticated && store.getters.checkToken.isFederation) {
            next()
          } else {
            store.dispatch("logout");
          }
        },
        component: () => import('../views/personel/AddPersonel.vue')
      },
      {
        path: '/editPersonel/:id',
        name: 'EditPersonel',
        beforeEnter(from, to, next) {
          store.dispatch("initAuth");
          if (store.getters.checkToken.authenticated && !store.getters.checkToken.isClub) {
            next()
          } else {
            store.dispatch("logout");
          }
        },
        component: () => import('../views/personel/EditPersonel.vue')
      },
      {
        path: '/personelInfo/:id',
        name: 'PersonelDetail',
        beforeEnter(from, to, next) {
          store.dispatch("initAuth");
          if (store.getters.checkToken.authenticated && !store.getters.checkToken.isClub) {
            next()
          } else {
            store.dispatch("logout");
          }
        },
        component: () => import('../views/personel/PersonelDetail.vue')
      },
      {
        path: '/competitionDetail/:id',
        name: 'CompetitionDetail',
        beforeEnter(from, to, next) {
          store.dispatch("initAuth");
          if (store.getters.checkToken.authenticated) {
            next()
          } else {
            store.dispatch("logout");
          }
        },
        component: () => import('../views/competitions/CompetitionDetail.vue')
      },
      {
        path: '/map',
        name: 'Map',
        beforeEnter(from, to, next) {
          store.dispatch("initAuth");
          if (store.getters.checkToken.authenticated && store.getters.checkToken.isFederation) {
            next()
          } else {
            store.dispatch("logout");
          }
        },
        component: () => import('../views/map/MapPage.vue')
      },
    ],
  },
  { path: "*", redirect: "/dashboard" }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


export default router